import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface LineHeightContextType {
  lineHeightLevel: number;
  setLineHeightLevel: React.Dispatch<React.SetStateAction<number>>;
  getLineHeight: (level: number) => string;
}

const STORAGE_KEY = 'line-height-level';

export const getLineHeight = (level: number): string => {
  switch (level) {
    case 0: return "1.5";
    case 1: return "1.75";
    case 2: return "2.0";
    case 3: return "2.25";
    case 4: return "2.5";
    default: return "1.5";
  }
};

export const LineHeightContext = createContext<LineHeightContextType | undefined>(undefined);

// Make sure to export this hook
export const useLineHeight = () => {
  const context = useContext(LineHeightContext);
  if (!context) {
    throw new Error('useLineHeight must be used within a LineHeightProvider');
  }
  return context;
};

export const LineHeightProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [lineHeightLevel, setLineHeightLevel] = useState(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(STORAGE_KEY);
      return saved ? parseInt(saved, 10) : 0;
    }
    return 0;
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(STORAGE_KEY, lineHeightLevel.toString());
      
      const styleTag = document.createElement('style');
      styleTag.id = 'line-height-styles';
      
      styleTag.textContent = `
        #main-content h1, 
        #main-content h2, 
        #main-content h3, 
        #main-content h4, 
        #main-content h5, 
        #main-content h6 {
          line-height: 1.2 !important;
        }

        #main-content p,
        #main-content li,
        #main-content td,
        #main-content th,
        #main-content label,
        #main-content input,
        #main-content textarea,
        #main-content div:not(:has(h1, h2, h3, h4, h5, h6)) {
          line-height: ${getLineHeight(lineHeightLevel)} !important;
        }
      `;

      const existingStyle = document.getElementById('line-height-styles');
      if (existingStyle) {
        existingStyle.remove();
      }

      document.head.appendChild(styleTag);
    }
  }, [lineHeightLevel]);

  return (
    <LineHeightContext.Provider value={{ 
      lineHeightLevel, 
      setLineHeightLevel,
      getLineHeight 
    }}>
      {children}
    </LineHeightContext.Provider>
  );
};