import React, { useState, useEffect, useRef } from 'react';
import Link from "next/link";
import { X, ExternalLink } from 'lucide-react';
import * as Dialog from "@radix-ui/react-dialog";
import FocusTrap from 'focus-trap-react';

interface CookieBannerProps {
  onAccept: (analyticsCookiesAccepted: boolean) => void;
  onReject: () => void;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ onAccept, onReject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const rejectButtonRef = useRef<HTMLButtonElement>(null);
  const acceptButtonRef = useRef<HTMLButtonElement>(null);
  const dialogContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isCookieAccepted = sessionStorage.getItem("cookieAccepted");
    if (!isCookieAccepted) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = (accepted: boolean) => {
    setIsOpen(false);
    if (accepted) {
      onAccept(true);
    } else {
      onReject();
    }
    sessionStorage.setItem("cookieAccepted", "true");
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        {/* Updated z-index here */}
        <Dialog.Overlay className="fixed z-[9999] inset-0 bg-black/50 data-[state=open]:animate-overlayShow" />
        <Dialog.Content 
          ref={dialogContentRef}
          // Updated z-index here
          className="fixed z-[9999] top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-blue p-[25px] shadow-... focus:outline-none data-[state=open]:animate-contentShow"
        >
          <FocusTrap
            focusTrapOptions={{
              initialFocus: () => acceptButtonRef.current,
              escapeDeactivates: false,
              allowOutsideClick: true,
              returnFocusOnDeactivate: true,
            }}
          >
            <div>
              <Dialog.Title className="text-light m-0 text-[17px] font-medium">
                Cookie Consent
              </Dialog.Title>
              <Dialog.Description className="text-light mt-[10px] mb-5 text-[15px] ">
                We use cookies to improve your experience. By using our site, you consent to our use of cookies.
              </Dialog.Description>
              <div className="mb-[15px]">
                <p className="text-light text-[13px]">
                  Learn more in our{" "}
                  <Link 
                    href="/privacy-policy" 
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-light hover:text-yellow focus:text-yellow font-bold underline inline-flex items-center focus-button-dark group`}
                  >
                    Privacy Policy
                    <span className="sr-only">(opens in a new tab)</span>
                    <ExternalLink className="ml-1 w-4 h-4 opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-300 ease-in-out text-yellow" />
                  </Link>
                </p>
              </div>
              <div className="mt-[25px] flex justify-end gap-[10px]">
                <button
                  ref={rejectButtonRef}
                  aria-label="reject"
                  className={`bg-transparent text-light hover:text-yellow transition-all focus:text-yellow duration-300 ease-in-out inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium focus-button-dark`}
                  onClick={() => handleClose(false)}
                  tabIndex={0}
                >
                  Reject
                </button>
                <button
                  ref={acceptButtonRef}
                  aria-label="accept"
                  className={`bg-transparent border-2 text-light hover:text-yellow hover:border-yellow focus:text-yellow focus:border-yellow hover:bg-opacity-90 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium  transition-all duration-300 ease-in-out focus-button-dark`}
                  onClick={() => handleClose(true)}
                  tabIndex={0}
                >
                  Accept
                </button>
              </div>
              <Dialog.Close asChild>
                <button
                  className={`text-light hover:text-yellow focus:text-yellow absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full transition-colors duration-300 ease-in-out focus-button-dark`}
                  aria-label="Close"
                  tabIndex={0}
                >
                  <X />
                </button>
              </Dialog.Close>
            </div>
          </FocusTrap>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CookieBanner;
