"use client";

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { gsap } from 'gsap';

const SpotlightAnimation: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const spotlightRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isOverClickable, setIsOverClickable] = useState(false);
  const [isOverDarkBackground, setIsOverDarkBackground] = useState(false);
  const currentSize = useRef(20);
  const [isClient, setIsClient] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsClient(true);
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleGlobalMouseMove = useCallback((e: MouseEvent) => {
    if (spotlightRef.current && !isMobile) {
      const x = e.clientX;
      const y = e.clientY;
      setMousePosition({ x, y });

      spotlightRef.current.style.opacity = '1';

      const elementUnderCursor = document.elementFromPoint(x, y);
      setIsOverClickable(elementUnderCursor ? isClickable(elementUnderCursor) : false);
      setIsOverDarkBackground(elementUnderCursor ? isDarkBackground(elementUnderCursor) : false);
    }
  }, [isMobile]);

  const handleGlobalMouseLeave = useCallback(() => {
    if (spotlightRef.current && !isMobile) {
      spotlightRef.current.style.opacity = '0';
    }
  }, [isMobile]);

  useEffect(() => {
    if (isClient && !isMobile) {
      window.addEventListener('mousemove', handleGlobalMouseMove);
      window.addEventListener('mouseleave', handleGlobalMouseLeave);

      return () => {
        window.removeEventListener('mousemove', handleGlobalMouseMove);
        window.removeEventListener('mouseleave', handleGlobalMouseLeave);
      };
    }
  }, [isClient, isMobile, handleGlobalMouseMove, handleGlobalMouseLeave]);

  useEffect(() => {
    if (spotlightRef.current && !isMobile) {
      gsap.to(spotlightRef.current, {
        duration: 0.3,
        width: isOverClickable ? 60 : 30,
        height: isOverClickable ? 60 : 30,
        x: mousePosition.x - (isOverClickable ? 40 : 20),
        y: mousePosition.y - (isOverClickable ? 40 : 20),
        backgroundColor: isOverDarkBackground ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
        ease: "power2.out",
        onUpdate: () => {
          if (spotlightRef.current) {
            currentSize.current = parseFloat(spotlightRef.current.style.width);
          }
        }
      });
    }
  }, [mousePosition, isOverClickable, isOverDarkBackground, isMobile]);

  const isClickable = (element: Element): boolean => {
    const clickableTags = ['A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
    const clickableRoles = ['button', 'link', 'checkbox', 'radio', 'switch', 'option'];

    return clickableTags.includes(element.tagName) ||
      clickableRoles.includes(element.getAttribute('role') || '') ||
      element.hasAttribute('onclick') ||
      window.getComputedStyle(element).cursor === 'pointer';
  };

  const isDarkBackground = (element: Element): boolean => {
    let currentElement: Element | null = element;
    while (currentElement && currentElement !== document.body) {
      const backgroundColor = window.getComputedStyle(currentElement).backgroundColor;
      const rgb = backgroundColor.match(/\d+/g);

      if (rgb && rgb.length === 3) {
        const [r, g, b] = rgb.map(Number);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        if (brightness < 128) {
          return true; // Dark background found
        }
        if (brightness > 128) {
          return false; // Light background found
        }
      }

      currentElement = currentElement.parentElement;
    }

    return false; // Default to light background if no definitive background color is found
  };

  if (!isClient || isMobile) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className="fixed inset-0 pointer-events-none z-50 overflow-hidden"
    >
      <div
        ref={spotlightRef}
        id="spotlight-animation"
        className="spotlight absolute rounded-full opacity-0 transition-opacity duration-300"
        style={{
          willChange: 'transform, width, height, background-color'
        }}
      ></div>
    </div>
  );
};

export default SpotlightAnimation;