// components/wrappers/ClientWrapperContent.tsx
"use client";

import React, { useEffect, useRef, useCallback, useState } from "react";
import { usePathname } from "next/navigation";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import SpotlightAnimation from "../ui/SpotlightAnimation";
import CookieBanner from "../ui/CookieBanner";
import { useLineHeight } from "./LineHeightProvider";
import AnimationToggle from "../modals/accessibility-widget/toggles/AnimationToggle";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

// Move the declare global block to the top level
declare global {
  interface Window {
    dataLayer: Array<unknown>;
    gtag: (...args: unknown[]) => void;
  }
}

interface ClientWrapperProps {
  children: React.ReactNode;
}

interface AccessibilitySettings {
  contrastMode: string;
  zoomLevel: number;
  isAnimationDisabled: boolean;
  isDarkMode: boolean;
  isSimple: boolean;
  isDyslexic: boolean;
  isScreenReaderActive: boolean;
  readingSpeed: string;
  isHighlightActive: boolean;
  position: string;
}

let smootherRef: ScrollSmoother | null = null;

const ClientWrapperContent: React.FC<ClientWrapperProps> = ({ children }) => {
  const pathname = usePathname();
  const smoothWrapperRef = useRef<HTMLDivElement>(null);
  const smoothContentRef = useRef<HTMLDivElement>(null);

  const [cookiesAccepted, setCookiesAccepted] = useState<boolean | null>(null);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const { lineHeightLevel } = useLineHeight();

  const getLineHeight = useCallback(() => {
    const levels = ["1.2", "1.5", "2.0", "2.25", "2.5"];
    return levels[lineHeightLevel] || "1.2";
  }, [lineHeightLevel]);

  const applyAccessibilitySettings = useCallback(
    (settings: AccessibilitySettings) => {
      const root = document.documentElement;
      const contentElement = document.getElementById("main-content");

      root.classList.toggle("dark", settings.isDarkMode);
      root.classList.toggle("reduce-motion", settings.isAnimationDisabled);
      root.classList.toggle("font-dyslexic", settings.isDyslexic);
      root.classList.toggle("simple-text", settings.isSimple);

      if (contentElement) {
        contentElement.style.zoom = `${100 + settings.zoomLevel * 20}%`;
        contentElement.style.lineHeight = getLineHeight();
      }

      smootherRef?.paused(settings.isAnimationDisabled);
    },
    [getLineHeight]
  );

  const initGoogleAnalytics = useCallback(() => {
    if (cookiesAccepted) {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-T2XKYG9YS9`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function (...args: unknown[]) {
          window.dataLayer.push(args);
        };
        window.gtag("js", new Date());
        window.gtag("config", "G-T2XKYG9YS9", {
          anonymize_ip: true,
          cookie_flags: "SameSite=None;Secure",
        });
      };
    }
  }, [cookiesAccepted]);

  const initScrollSmoother = useCallback(() => {
    if (smoothWrapperRef.current && smoothContentRef.current) {
      if (smootherRef) smootherRef.kill();
      smootherRef = ScrollSmoother.create({
        wrapper: smoothWrapperRef.current,
        content: smoothContentRef.current,
        smooth: /Mobi|Android/i.test(navigator.userAgent) ? 0.5 : 1,
        effects: false,
      });
    }
  }, []);

  useEffect(() => {
    const isCookieAccepted = sessionStorage.getItem("cookieAccepted") === "true";
    setCookiesAccepted(isCookieAccepted);

    if (isCookieAccepted) initGoogleAnalytics();
  }, [initGoogleAnalytics]);

  useEffect(() => {
    setShowCookieBanner(sessionStorage.getItem("cookieAccepted") === null);
  }, []);

  useEffect(() => {
    initScrollSmoother();
  }, [pathname, initScrollSmoother]);

  useEffect(() => {
    applyAccessibilitySettings({
      contrastMode: "normal",
      zoomLevel: 0,
      isAnimationDisabled: false,
      isDarkMode: false,
      isSimple: false,
      isDyslexic: false,
      isScreenReaderActive: false,
      readingSpeed: "normal",
      isHighlightActive: false,
      position: "bottom-left",
    });
  }, [applyAccessibilitySettings]);

  return (
    <>
      {!cookiesAccepted && showCookieBanner && (
        <CookieBanner
          onAccept={() => {
            setCookiesAccepted(true);
            sessionStorage.setItem("cookieAccepted", "true");
            setShowCookieBanner(false);
            initGoogleAnalytics(); // Initialize analytics on acceptance
          }}
          onReject={() => {
            setCookiesAccepted(false);
            sessionStorage.setItem("cookieAccepted", "false");
            setShowCookieBanner(false);
          }}
        />
      )}

      <div id="smooth-wrapper" ref={smoothWrapperRef}>
        <div id="smooth-content" ref={smoothContentRef}>
          {children}
        </div>
      </div>

      {!cookiesAccepted && !showCookieBanner && <SpotlightAnimation />}
      <AnimationToggle />
    </>
  );
};

export default ClientWrapperContent;
export { smootherRef };
