"use client";

import React, { useState } from 'react';
import { MdAnimation } from "react-icons/md";
import { useAnimationDisableToggle } from '@/components/wrappers/AnimationDisableProvider';
import Toast from '@/components/ui/Toast';

const AnimationToggle = () => {
  const { animationsDisabled, toggleAnimations } = useAnimationDisableToggle();
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleToastMessage = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 5000);
  };

  const handleToggle = () => {
    toggleAnimations();
    const message = animationsDisabled 
      ? "Animations have been enabled"
      : "Animations have been disabled";
    handleToastMessage(message);
  };

  const focusClasses = 
    "focus:outline-none focus-visible:ring-4 focus-visible:ring-blue focus-visible:ring-opacity-100 focus-visible:ring-offset-2 focus-visible:ring-offset-white " +
    "hover:ring-4 hover:ring-blue hover:ring-opacity-100 hover:ring-offset-2 hover:ring-offset-white " +
    "transition-shadow duration-300 rounded";

  return (
    <>
      <button
      id='animation-toggle'
        onClick={handleToggle}
        className={`
          fixed bottom-4 z-10 left-4 p-3 rounded-full shadow-lg 
          transition-colors duration-200
          ${focusClasses}
          ${animationsDisabled 
            ? 'bg-gray-200 text-blue-500 hover:bg-gray-300 border-2  border-blue' 
            : 'bg-blue text-white hover:bg-blue border-2 border-white'
          }
        `}
        aria-label={`${animationsDisabled ? 'Enable' : 'Disable'} animations`}
        aria-pressed={animationsDisabled}
      >
        <MdAnimation className="w-5 h-5" />
        <span className="sr-only">
          {animationsDisabled ? 'Animations Disabled' : 'Animations Enabled'}
        </span>
      </button>

      <Toast
        message={toastMessage}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
      />
    </>
  );
};

export default AnimationToggle;